const OPTIONS_KEY_NAME = 'options'
const REGISTER_MEMBERS_KEY_NAME = 'register_members'

const DEFAULT_OPTIONS = {
  filters: {},
  page: 1,
  pageCount: process.env.VUE_APP_MAX_COUNT_IN_RESULT,
  sortBy: {}
}
const VERSION = process.env.VUE_APP_VERSION
const STORAGE_KEY_VERSION = 'version'

const load = (key) => JSON.parse(localStorage.getItem(key))
const save = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const updateStorage = () => {
  const version = load(STORAGE_KEY_VERSION)
  if (+version === +VERSION) return
  localStorage.clear()
  save(STORAGE_KEY_VERSION, VERSION)
}

const saveOptions = (name, data) => {
  let history = load(OPTIONS_KEY_NAME)
  if (!history) {
    history = {}
    if (!history[name]) {
      history[name] = {}
    }
  }
  history[name] = data
  save(OPTIONS_KEY_NAME, history)
}

const loadOptions = (name, defaultData = null) => {
  updateStorage()
  const history = load(OPTIONS_KEY_NAME)
  if (!history || !history[name]) {
    return defaultData
  }

  return history[name]
}

export const saveSroListOptions = (options) => {
  saveOptions('SroList', options)
}

export const saveMemberListBySroOptions = (options) => {
  saveOptions('MemberListBySro', options)
}

export const saveMemberListByAllSroOptions = (options) => {
  saveOptions('MemberListByAllSro', options)
}

export const loadSroListOptions = () => {
  return loadOptions('SroList', DEFAULT_OPTIONS)
}

export const loadMemberListBySroOptions = () => {
  return loadOptions('MemberListBySro', DEFAULT_OPTIONS)
}

export const loadMemberListByAllSroOptions = () => {
  return loadOptions('MemberListByAllSro', DEFAULT_OPTIONS)
}

export const loadRegisterMembersState = (sroId) => {
  updateStorage()
  const state = load(REGISTER_MEMBERS_KEY_NAME)
  if (state && state[sroId] !== undefined) {
    return state[sroId]
  }
  return null
}

export const saveRegisterMembersState = (sroId, value) => {
  let state = load(REGISTER_MEMBERS_KEY_NAME)
  if (!state) {
    state = {}
  }

  state[sroId] = value
  save(REGISTER_MEMBERS_KEY_NAME, state)
}
