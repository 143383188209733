<template>
  <div class="table">
    <table class="table__list">
      <tr>
        <th @click="sort('state')">
          <div>
            <sort-icon :sort="sortBy.state"/>
            <div>
              Статус
            </div>
          </div>
        </th>
        <th @click="sort('registration_number')">
          <div>
            <sort-icon :sort="sortBy.registration_number"/>
            <div>Регистрационный номер</div>
          </div>
        </th>
        <th @click="sort('full_description')">
          <sort-icon :sort="sortBy.full_description"/>
          Полное наименование
        </th>

        <th @click="sort('place')">
          <div>
            <sort-icon :sort="sortBy.place"/>
            <div>Адрес</div>
          </div>
        </th>

        <th @click="sort('region_number')">
          <div>
            <sort-icon :sort="sortBy.region_number"/>
            <div>
              Субъект РФ
            </div>
          </div>
        </th>

        <th @click="sort('federal_district_id')">
          <div>
            <sort-icon :sort="sortBy.federal_district_id"/>
            <div>
              Федеральный округ
            </div>
          </div>
        </th>
      </tr>
      <tr
        v-for="sro in list"
        :key="'sro-' + sro.id"
        @click="showSro(sro.id)"
      >
        <td>
          <div style="display: flex; justify-content: center">
            <div
              class="status"
              :class="{
                'status-enabled': sro.state === $options.STATUS_ENABLED.id,
                'status-disabled': sro.state === $options.STATUS_DISABLED.id
              }"
              :title="getTitleStatus(sro.state)"
            ></div>
          </div>
        </td>
        <td v-html="highlight(sro.registration_number)"></td>
        <td v-html="highlight(sro.full_description)"></td>
        <td>{{ sro.place }}</td>
        <td>{{ sro.region_name }}</td>
        <td>{{ sro.federal_district ? sro.federal_district.title : '' }}</td>
      </tr>
    </table>
    <div class="card-list">
      <div
        class="card"
        v-for="sro in list"
        :key="'sro-card-' + sro.id"
      >
        <div class="card__header">
            <div class="status"
                 :class="{
                    'status-enabled': sro.state === $options.STATUS_ENABLED.id,
                    'status-disabled': sro.state === $options.STATUS_DISABLED.id
                 }"
                 :title="getTitleStatus(sro.state)"
            ></div>
          <div
              v-html="highlight(sro.short_description)"
          />
          <div class="status"></div>
        </div>

        <div class="card__title">
          Рег. номер:
        </div>
        <div class="card__value"
             v-html="highlight(sro.registration_number)"
        />

        <div class="card__title">
          Полное наименование:
        </div>
        <div class="card__value"
             v-html="highlight(sro.full_description)"
        />

        <div class="card__title">
          Статус:
        </div>
        <div class="card__value">
          {{ getTitleStatus(sro.state) }}
        </div>

        <div class="card__title">
          Адрес:
        </div>
        <div class="card__value">
          {{ sro.place }}
        </div>

        <div class="card__title">
          Субъект РФ:
        </div>
        <div class="card__value">
          {{ sro.region_name }}
        </div>

        <div class="card__title">
          Федеральный округ:
        </div>
        <div class="card__value">
          {{ sro.federal_district ? sro.federal_district.title : '' }}
        </div>

        <v-btn
          class="mt-5"
          color="secondary"
          @click="showSro(sro.id)"
        >
          Подробнее
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import TableMixin from '../../mixins/TableMixin'
import { SRO_STATE_DISABLED, SRO_STATE_ENABLED, FILTER_SRO_STATE_VALUES } from '../../helpers/dictionary'

export default {
  name: 'SroTable',
  mixins: [TableMixin],
  STATUS_ENABLED: SRO_STATE_ENABLED,
  STATUS_DISABLED: SRO_STATE_DISABLED,
  STATUS_VALUES: FILTER_SRO_STATE_VALUES,

  methods: {
    showSro (sroId) {
      this.$router.push({
        name: 'SroItem',
        params: { id: sroId }
      })
    },
    getTitleStatus (id) {
      return this.$options.STATUS_VALUES.find(el => el.id === id).title
    }
  }
}
</script>

<style scoped>

</style>
