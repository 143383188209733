<template>
  <div class="breadcrumbs">
    <slot></slot>
  </div>

</template>

<script>
export default {
  name: 'BreadcrumbsComponent'
}
</script>

<style scoped>

</style>
