<template>
  <div>
    <breadcrumbs-component>
      <span>Реестр СРО</span>
    </breadcrumbs-component>
<!--    <div-->
<!--      class="page-title"-->
<!--    >-->
<!--      Саморегулируемые организации в области строительства, реконструкции, <br>-->
<!--      капитального ремонта, сноса объектов капитального строительства-->
<!--    </div>-->
    <control-panel
      label-search="Рег. номер, Наименование"
      v-bind:search-string.sync="options.searchString"
      :chips="chips"
      @show-filter="filterIsShow = true"
      @show-sort="sortIsShow = true"
      @submit-search="submitSearch"
      @delete-filter="deleteFilter"
    />
    <div
      style="position: relative"
      v-if="!errorMessage"
    >
      <sort-form
        :value="options.sortBy"
        :sort-options="filterOptions"
        :sort-is-show.sync="sortIsShow"
        @submit="submitSort"
      />
      <filter-form
        :filter-options="filterOptions"
        :filters="options.filters"
        :filter-is-show.sync="filterIsShow"
        @submit-filter="submitFilter"
      />
      <div
        v-if="list"
      >
        <div v-if="list.length > 0">
          <sro-table
            :search-string="options.searchString"
            :sort-by="options.sortBy"
            :list="list"
            @sort="sortBy"
          />
          <pagination
            :page-count="countPage"
            :page="page"
            :count-all="count"
            :max-count-on-page="options.pageCount"
            @check-page="setPage"
            @change-max-count="changeMaxCount"
          />
        </div>
        <div
          class="message"
          v-else
        >Записи, удовлетворяющие критериям поиска отсутствуют</div>
      </div>
      <spinner
        v-else-if="spinnerIsShow"
      />
    </div>
    <error-message
      v-else
    >{{ errorMessage }} </error-message>
  </div>
</template>

<script>
import ListMixin from '../../mixins/ListMixin'
import { FILTER_TYPE_EQ, FILTER_TYPE_LIKE, FILTER_SRO_STATE_VALUES } from '../../helpers/dictionary'
import { getSroList, getDictionaries } from '../../helpers/api'
import { loadSroListOptions, saveSroListOptions } from '../../helpers/history'
import BreadcrumbsComponent from '../../components/breadcrumbs/BreadcrumbsComponent'
import SroTable from '../../components/sro/SroTable'
import ControlPanel from '../../components/ControlPanel'

export default {
  name: 'SroList',
  components: {
    BreadcrumbsComponent,
    ControlPanel,
    SroTable
  },
  mixins: [ListMixin],
  data: () => ({
    filterOptions: [
      {
        name: 'state',
        title: 'Статус',
        type: FILTER_TYPE_EQ,
        values: FILTER_SRO_STATE_VALUES
      },
      {
        name: 'registration_number',
        title: 'Регистрационный номер',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'full_description',
        title: 'Полное наименование СРО',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'place',
        title: 'Адрес',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'region_number',
        title: 'Субъект РФ',
        type: FILTER_TYPE_EQ,
        values: []
      },
      {
        name: 'federal_district_id',
        title: 'Федеральный округ',
        type: FILTER_TYPE_EQ,
        values: []
      }
    ]
  }),
  methods: {
    saveOptions: saveSroListOptions,
    loadOptions: loadSroListOptions,
    async getDataList () {
      return await getSroList(this.options)
    },
    async getDictionaries () {
      this.errorMessage = ''
      const requestData = await getDictionaries({ dictionaries: ['federal_districts', 'region_number'] })

      if (!this.validateRequestData(requestData)) {
        return
      }

      this
        .filterOptions
        .find(el => el.name === 'federal_district_id')
        .values = requestData.data.federal_districts

      this.filterOptions
        .find(el => el.name === 'region_number')
        .values = requestData.data.region_number
    }
  }
}
</script>

<style scoped lang="sass">
//@import '../../assets/css/style'
</style>
