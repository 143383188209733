var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[_c('table',{staticClass:"table__list"},[_c('tr',[_c('th',{on:{"click":function($event){return _vm.sort('state')}}},[_c('div',[_c('sort-icon',{attrs:{"sort":_vm.sortBy.state}}),_c('div',[_vm._v(" Статус ")])],1)]),_c('th',{on:{"click":function($event){return _vm.sort('registration_number')}}},[_c('div',[_c('sort-icon',{attrs:{"sort":_vm.sortBy.registration_number}}),_c('div',[_vm._v("Регистрационный номер")])],1)]),_c('th',{on:{"click":function($event){return _vm.sort('full_description')}}},[_c('sort-icon',{attrs:{"sort":_vm.sortBy.full_description}}),_vm._v(" Полное наименование ")],1),_c('th',{on:{"click":function($event){return _vm.sort('place')}}},[_c('div',[_c('sort-icon',{attrs:{"sort":_vm.sortBy.place}}),_c('div',[_vm._v("Адрес")])],1)]),_c('th',{on:{"click":function($event){return _vm.sort('region_number')}}},[_c('div',[_c('sort-icon',{attrs:{"sort":_vm.sortBy.region_number}}),_c('div',[_vm._v(" Субъект РФ ")])],1)]),_c('th',{on:{"click":function($event){return _vm.sort('federal_district_id')}}},[_c('div',[_c('sort-icon',{attrs:{"sort":_vm.sortBy.federal_district_id}}),_c('div',[_vm._v(" Федеральный округ ")])],1)])]),_vm._l((_vm.list),function(sro){return _c('tr',{key:'sro-' + sro.id,on:{"click":function($event){return _vm.showSro(sro.id)}}},[_c('td',[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"status",class:{
              'status-enabled': sro.state === _vm.$options.STATUS_ENABLED.id,
              'status-disabled': sro.state === _vm.$options.STATUS_DISABLED.id
            },attrs:{"title":_vm.getTitleStatus(sro.state)}})])]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.highlight(sro.registration_number))}}),_c('td',{domProps:{"innerHTML":_vm._s(_vm.highlight(sro.full_description))}}),_c('td',[_vm._v(_vm._s(sro.place))]),_c('td',[_vm._v(_vm._s(sro.region_name))]),_c('td',[_vm._v(_vm._s(sro.federal_district ? sro.federal_district.title : ''))])])})],2),_c('div',{staticClass:"card-list"},_vm._l((_vm.list),function(sro){return _c('div',{key:'sro-card-' + sro.id,staticClass:"card"},[_c('div',{staticClass:"card__header"},[_c('div',{staticClass:"status",class:{
                  'status-enabled': sro.state === _vm.$options.STATUS_ENABLED.id,
                  'status-disabled': sro.state === _vm.$options.STATUS_DISABLED.id
               },attrs:{"title":_vm.getTitleStatus(sro.state)}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(sro.short_description))}}),_c('div',{staticClass:"status"})]),_c('div',{staticClass:"card__title"},[_vm._v(" Рег. номер: ")]),_c('div',{staticClass:"card__value",domProps:{"innerHTML":_vm._s(_vm.highlight(sro.registration_number))}}),_c('div',{staticClass:"card__title"},[_vm._v(" Полное наименование: ")]),_c('div',{staticClass:"card__value",domProps:{"innerHTML":_vm._s(_vm.highlight(sro.full_description))}}),_c('div',{staticClass:"card__title"},[_vm._v(" Статус: ")]),_c('div',{staticClass:"card__value"},[_vm._v(" "+_vm._s(_vm.getTitleStatus(sro.state))+" ")]),_c('div',{staticClass:"card__title"},[_vm._v(" Адрес: ")]),_c('div',{staticClass:"card__value"},[_vm._v(" "+_vm._s(sro.place)+" ")]),_c('div',{staticClass:"card__title"},[_vm._v(" Субъект РФ: ")]),_c('div',{staticClass:"card__value"},[_vm._v(" "+_vm._s(sro.region_name)+" ")]),_c('div',{staticClass:"card__title"},[_vm._v(" Федеральный округ: ")]),_c('div',{staticClass:"card__value"},[_vm._v(" "+_vm._s(sro.federal_district ? sro.federal_district.title : '')+" ")]),_c('v-btn',{staticClass:"mt-5",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.showSro(sro.id)}}},[_vm._v(" Подробнее ")])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }